.homepage {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.homepage-name {
  color: rgba(255, 255, 255, 0.5);
  font-size: 16vw;
  font-weight: bolder;
  margin: 0;
}

.homepage-description {
  color: rgba(255, 255, 255, 0.5);
  font-size: 2em;
  font-style: italic;
}

.homepage-links {
  display: flex;
}

.homepage .button {
  margin: 1em;
}

/* MOBILE + TABLET PORTRAIT */
@media screen and (max-device-width: 780px) and (orientation: portrait) {
  .homepage .button {
    margin: 0.5em;
  }

  .homepage-name {
    font-size: 8em;
  }

  .homepage-links {
    flex-direction: column;
  }
}
