div.dropdown-menu.show {
  background-color:rgba(255, 255, 255, 0.8);
  font-family: "optima";
}

.navbar-color-choice {
  color: black;
}

.nav-link {
  font-family: "optima";
}

.TNavContainer {
  background-color: rgba(255, 255, 255, 0.5);
}

#collasible-nav-dropdown {
  color: black;
}
