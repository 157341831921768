footer {
  background-color: rgba(255, 255, 255, 0.5);
  font-style: italic;
  text-align: center;
}

.footer-text {
  margin: 0;
  color: black;
  padding-top: 1em;
}

.footer-text:hover {
  color: white;
}

.footer-contact-icon {
  color: black;
  margin: 1em;
}

.footer-contact-icon:hover {
  color: white;
}
