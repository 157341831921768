a:link, a:active, a:hover, a:visited {
  text-decoration: none;
}

.App {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  font-family: "optima";
}

body {
  background-image: url("../public/mountainTrees.jpeg");
  background-size: cover;
}

.button {
  background-color: rgba(20, 39, 68, 0.15);
  border-radius: 8px;
  border-width: 0;
  color: white;
  padding: 10px 12px;
}

.button:hover {
  background-color: rgba(20, 39, 68, 0.55);
  color: white;
  transform: translateY(-1px);
}

.button:active {
  background-color: #f0f0f1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}
