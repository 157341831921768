.resume {
  background-color: rgba(255, 255, 255, 0.75);
  font-family: "optima";
  margin: 2em;
  padding: 4em;
  text-align: center;
}

.resume-award {
  font-style: italic;
}

.resume-page-title {
  font-weight: 800;
}

.resume-left {
  text-align: left;
}

.resume-right {
  text-align: right;
}

.resume-header-container {
  align-items: center;
  display: flex;
  justify-content: center;
}

.resume-name {
  flex-grow: 1;
  margin: 0;
  text-align: left;
  font-style: italic;
}

.resume-contact-section p {
  align-items: right;
  flex-grow: 1;
  margin: 0;
}

.resume-item-container {
  display: flex;
  margin-right: 1em;
}

.resume-date {
  padding-right: 1em;
}

.resume-item {
  font-weight: bold;
}

.resume-item-info {
  font-style: italic;
}

.resume-description {
  margin-bottom: 2em;
}

.resume-portfolio-link {
  color: black;
  text-decoration: none;
}

.resume-portfolio-link:hover {
  color: white;
}

/* MOBILE + TABLET PORTRAIT */
@media screen and (max-device-width: 780px) and (orientation: portrait) {
  .resume {
    margin: 0;
    padding: 1em;
  }

  .resume-header {
    flex-direction: column;
  }

  .resume-left {
    text-align: center;
  }

  .resume-right {
    text-align: center;
  }

  .resume-header-container {
    flex-direction: column;
  }

  .resume-item-container {
    flex-direction: column;
  }

  .resume-date {
    padding: 0;
  }
}
