.portfolio {
  background-color: rgba(255, 255, 255, 0.75);
  font-family: "optima";
  margin: 2em;
  padding: 4em;
}

.portfolio-page-title {
  font-weight: 800;
}

.portfolio-left {
  text-align: left;
}

.portfolio-item-container {
  display: flex;
  margin-right: 1em;
}

.portfolio-date {
  padding-right: 1em;
}

.portfolio-item {
  flex-grow: 1;
  font-weight: bold;
}

.live-button {
  background-color: #9dacc3;
  border-radius: 8px;
  border-width: 0;
  color: white;
  margin: 0 .5em 2em .5em;
  padding: 10px 12px;
}

.live-button:hover {
  color: white;
  background-color: rgb(192,203,222);
  font-weight: bold;
  transform: translateY(-1px);
}

.live-button:active {
  background-color: #f0f0f1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}

.resume-link {
  color: black;
  text-decoration: none;
}

.resume-link:hover {
  color: white;
}

/* MOBILE + TABLET PORTRAIT */
@media screen and (max-device-width: 780px) and (orientation: portrait) {
  .portfolio {
    margin: 0;
    padding: 1em;
  }

  .portfolio-header {
    flex-direction: column;
  }

  .portfolio-item-container {
    flex-direction: column;
  }

  .portfolio-date {
    padding: 0;
  }

  .portfolio-left {
    text-align: center;
  }
}
